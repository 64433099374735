/**
 * An enumeration of supported payment types.
 * 1=CreditCard, 2=CustomerFacingCC, 3=GiftCard, 4=ACH, 5=Crypto
 */
export enum PaymentMethodEnum {
  CreditCard = 1,
  CustomerFacingCC = 2,
  ACH = 4
}

export const PaymentMethodEnumNames = {
  [PaymentMethodEnum.CreditCard]: 'Credit Card',
  [PaymentMethodEnum.CustomerFacingCC]: 'Customer Facing Credit Card',
  [PaymentMethodEnum.ACH]: 'ACH'
};

export const PaymentMethodComponentNames = {
  [PaymentMethodEnum.CreditCard]: 'Credit Card',
  [PaymentMethodEnum.CustomerFacingCC]: 'Credit Card',
  [PaymentMethodEnum.ACH]: 'ACH'
};

export enum SelectedProcessorPaymentMethods {
  CreditCard = 1,
  CustomerFacingCC = 2,
  ACH = 4
}

export const SelectedProcessorPaymentMethodNames = {
  [SelectedProcessorPaymentMethods.CreditCard]: 'In Store Credit Card Merchant',
  [SelectedProcessorPaymentMethods.ACH]: 'Online ACH Merchant',
  [SelectedProcessorPaymentMethods.CustomerFacingCC]: 'Online Credit Card Merchant'
};
