import { TerminalId } from '@por/por-pay/shared'

export class MeetInTheCloudUtil {
  static isMeetInTheCloudTerminal(terminalId: TerminalId | undefined) {
    // The meet in the cloud DeviceId that is stored as TerminalId
    // follows this format e.g. cedevide::{deviceId}, so finding
    // the characters  :: will work as a flag.
    return terminalId && terminalId.indexOf('::') > -1;
  }
}
