/* eslint-disable @typescript-eslint/naming-convention */
export enum PorPayErrorCode {
    Pending = 102,
    OK = 200,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllowed = 405,
    NotAcceptable = 406,
    Conflict = 409,
    Gone = 410,
    UnprocessableEntity = 422,
    TooManyRequests = 429,
    UserCancelled = 499,
    ServerError = 500,
    NotImplemented = 501,
    BadGateway = 502,
    ServiceUnavailable = 503,
    GatewayTimeout = 504,
    IncompleteAccountSetup = 510
}

export const CayanErrorCodeToPorPayErrorCode: { [key: string]: PorPayErrorCode } = {
    NOT_FOUND: PorPayErrorCode.NotFound,
    REQUIRED: PorPayErrorCode.BadRequest,
    VALIDATION: PorPayErrorCode.NotAcceptable,
    SERVER: PorPayErrorCode.ServerError,
    SERVER_REQUIRED: PorPayErrorCode.BadRequest,
    SERVER_VALIDATION: PorPayErrorCode.NotAcceptable
};

export const CayanReasonToPorPayErrorMessage: { [key: string]: string } = {
    cardnumber: 'The card number is invalid',
    UNEXPECTED: 'An unexpected error occurred on the server and the request was not processed',
    UNAVAILABLE: 'The server is currently unavailable for tokenizing payment information',
    UNAUTHORIZED: 'The server rejected the Web API key supplied',
    PAYMENT_NOT_SUPPORTED: 'The payment method is not supported by the merchant.'
};
