/* eslint-disable @typescript-eslint/naming-convention */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

import { PaymentMethodEnum } from '../enums';
import {AmountRequestedAdditional} from '../../transactions';

export interface Transaction {
    /**
     * The primary key for the Payment App Transaction table
     */
    TransactionId?: string;
    /**
     * The parent key for the Payment App Transaction table
     */
    ParentTransactionId?: string | null;
    /**
     * Whether this Transaction is soft deleted or not
     */
    Hidden?: number;
    /**
     * The Global OrganizationId that this Transaction was created for
     */
    OrganizationId?: number;
    /**
     * The ID that the credit card processor uses to identify this Transaction. This ID can be used to make actions against the credit card processor directly.
     */
    ProcessorTransactionId?: string | null;
    /**
     * The TerminalId that this Transaction was created on, if one exists
     */
    TerminalId?: string | null;
    /**
     * The ProcessorId that corresponds to the Payment App Processor table
     */
    ProcessorId?: string;
    /**
     * The CustomerId that corresponds to the Payment App Customer table
     */
    CustomerId?: number;
    /**
     * The integer representing the amount of the smallest denomination of currency. For example, 10000 Amount with a CurrencyCode of USD for $100.00
     */
    Amount_Requested?: number;
    /**
     * The integer representing the amount of the smallest denomination of currency. For example, 10000 Amount with a CurrencyCode of USD for $100.00
     */
    Amount_Processed?: number;
    /**
     * The 3-letter ISO 4217 uppercase currency code
     */
    CurrencyCode?: string;
    TransactionResultEnum?: TransactionResultEnum;
    TransactionTypeEnum?: TransactionTypeEnum;
    /**
     * The last four digits of the input credit card (when available)
     */
    CCLastFourDigits?: string | null;
    /**
     * The expiration date of the input credit card (when available)
     */
    CCExpiration?: string | null;
    /**
     * The issuer of the input credit card (when available)
     */
    CCIssuer?: string | null;
    /**
     * The tokenization string of the input credit card (when available)
     */
    CCToken?: string | null;
    /**
     * The ISO 7816-5 application identifier
     */
    AID?: string | null;
    /**
     * The percentage of amount requested that should be applied as a fee
     */
    ApplicationFeeBasisPoints?: number | null;
    IsCustomerFacing?: 0 | 1;
    /**
     * Whether this Transaction is a Mail Order/Telephone Order (MOTO) transaction or not
     */
    IsMOTO?: 0 | 1;
    PaymentMethodEnum?: PaymentMethodEnum;
    RemoteDepotId?: string;
    Created?: string;
    /**
     * An enumeration of supported payment types to exclude on transaction creation.
     * 1=CreditCard, 4=ACH
     */
    ExcludedPaymentMethods?: PaymentMethodEnum[];
    AllowedPaymentMethods?: PaymentMethodEnum[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [k: string]: any;
    Amount_Requested_Additional?: AmountRequestedAdditional[];
}

/**
 * The state of the Transaction and can be any of the following:
 *  1=PENDING, 2=DECLINED, 3=CANCELLED, 4=ERROR, 5=APPROVED
 *  Initially created as 1 (PENDING) and moves to any of the other 4 states.
 */
export enum TransactionResultEnum {
    PENDING = 1,
    DECLINED = 2,
    CANCELLED = 3,
    ERROR = 4,
    APPROVED = 5
}
/**
 * The type of the Transaction and can be any of the following:
 *  1=Charge, 2=Refund, 3=PreAuth, 4=PostAuth, 5=Void, 6=IndRefund, 7=FollowOn, 8=RecurringBilling
 */
export enum TransactionTypeEnum {
    Charge = 1,
    Refund = 2,
    PreAuth = 3,
    PostAuth = 4,
    Void = 5,
    IndRefund = 6,
    FollowOn = 7,
    RecurringBilling = 8,
    TokenizeCard = 9
}
